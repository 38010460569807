import React from "react";
import { FiRefreshCw } from "react-icons/fi";
import styled, { keyframes } from "styled-components/macro";
import { Container } from "./Grid";
import Text from "./Text";

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled(FiRefreshCw)`
  animation: ${rotate} 2s linear infinite;
`;
const Loading = props => {
  return (
    <Container>
      <Text py={4} textAlign="center" color="muted">
        <Spinner />
      </Text>
    </Container>
  );
};

export default Loading;
