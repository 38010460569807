import { rgba, setLightness } from "polished";

const colors = {
  primary: "#7a0033",
  secondary: "#8c9b1b",
  gray: "#41363b",
  text: "#41363b",
  textOnDark: "#fff", // For image overlays.
  textOnLight: "#41363b", // For image overlays, used as the text shadow.
  onPrimary: "#fff",
  onSecondary: "#fff",
  background: "#fff",
  toolbarBackground: "#fff"
};

export default {
  colors: {
    ...colors,
    muted: setLightness(0.6, colors.gray),
    semiMuted: setLightness(0.35, colors.gray),
    soft: [setLightness(0.97, colors.gray), setLightness(0.94, colors.gray)]
  },
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  lineHeights: ["14px", "16px", "20px", "24px", "28px", "36px", "48px", "64px"],
  fontWeights: {
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    bold: "700"
    // black: "900"
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  borders: {
    primary: "1px solid currentColor",
    soft: `1px solid ${rgba(colors.text, 0.2)}`
  },
  shadows: {
    xs: `0 0 5px  ${rgba(colors.gray, 0.3)}, 0 0 10px ${rgba(
      colors.gray,
      0.15
    )}}`,
    sm: `0 0 10px ${rgba(colors.gray, 0.2)}, 0 0 20px ${rgba(
      colors.gray,
      0.1
    )}`,
    md: `0 0 20px ${rgba(colors.gray, 0.1)}, 0 0 40px ${rgba(
      colors.gray,
      0.05
    )}`,
    smInset: `0 0 10px inset ${rgba(colors.gray, 0.2)}, 0 0 20px inset ${rgba(
      colors.gray,
      0.1
    )}`
  }
};
