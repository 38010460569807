import React, { Suspense } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo-hooks";
import { ThemeProvider } from "styled-components/macro";
import EventLayout from "./EventLayout";
import { fragment as ImageFragment } from "../../components/Image";
import Loading from "../../components/Loading";
import useScrollRestoration from "../../components/useScrollRestoration";

export default function Event(props) {
  const { lang, projectId, children, location } = props;
  const {
    data: { event: { name, theme, logo, navItems } = {} } = {},
    errors
  } = useQuery(query, {
    variables: { projectId, lang },
    suspend: false
  });
  useScrollRestoration(location);
  return (
    <ThemeProvider theme={mergeTheme(theme)}>
      <EventLayout
        name={name}
        logo={logo}
        navItems={navItems}
        errors={errors}
        {...props}
      >
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </EventLayout>
    </ThemeProvider>
  );
}

const mergeTheme = (theme = {}) => parentTheme => ({
  ...parentTheme,
  ...theme,
  colors: { ...parentTheme.colors, ...theme.colors }
});

export const fragment = gql`
  fragment EventTheme on Project {
    logo {
      ...Image
    }
    name
    navItems: pages {
      id
      url
      title
    }
    theme {
      colors {
        primary
        secondary
      }
    }
  }
  ${ImageFragment}
`;

export const query = gql`
  query EventThemeAndLayout($projectId: ID!, $lang: String) {
    event(id: $projectId, lang: $lang) {
      ...EventTheme
    }
  }
  ${fragment}
`;
