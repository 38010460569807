import React, { Suspense } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo-hooks";
import { ThemeProvider } from "styled-components/macro";
import SiteLayout from "../event/EventLayout";
import { fragment as ImageFragment } from "../../components/Image";
import Loading from "../../components/Loading";
import useScrollRestoration from "../../components/useScrollRestoration";

export default function Site(props) {
  return (
    <Suspense fallback={<Loading />}>
      <SiteInner {...props} />
    </Suspense>
  );
}

function SiteInner(props) {
  const { lang, siteId, children, location } = props;
  const {
    data: { project: { name, theme, logo, navItems } = {} } = {},
    errors
  } = useQuery(query, {
    variables: { siteId, lang },
    suspend: true
  });
  useScrollRestoration(location);
  return (
    <ThemeProvider theme={mergeTheme(theme)}>
      <SiteLayout
        name={name}
        logo={logo}
        navItems={navItems}
        errors={errors}
        {...props}
      >
        {children}
      </SiteLayout>
    </ThemeProvider>
  );
}

const mergeTheme = (theme = {}) => parentTheme => ({
  ...parentTheme,
  ...theme,
  colors: { ...parentTheme.colors, ...theme.colors }
});

export const fragment = gql`
  fragment SiteTheme on Project {
    logo {
      ...Image
    }
    brandId
    name
    navItems: pages {
      id
      url
      title
    }
    theme {
      colors {
        primary
        secondary
      }
    }
  }
  ${ImageFragment}
`;

export const query = gql`
  query SiteThemeAndLayout($siteId: ID!, $lang: String) {
    project(id: $siteId, lang: $lang) {
      ...SiteTheme
    }
  }
  ${fragment}
`;
