import React from "react";

export default function forwardProps(...shouldForwardFns) {
  function getForwardedProps(props, Component) {
    return Object.keys(props).reduce((acc, name) => {
      if (shouldForwardFns.every(fn => fn(name, Component))) {
        acc[name] = props[name];
      }
      return acc;
    }, {});
  }

  return function Cleaned(Component) {
    const Cleaned = function Cleaned(props) {
      return <Component {...getForwardedProps(props, Component)} />;
    };
    if (typeof Component === "string") {
      Cleaned.displayName = Component;
    } else if (Component.displayName) {
      Cleaned.displayName = Component.displayName;
    } else if (Component.name) {
      Cleaned.displayName = Component.name;
    }
    return Cleaned;
  };
}

export function removePropTypes(...components) {
  const propsToRemove = components.flatMap(
    component => Object.keys(component.propTypes) // eslint-disable-line react/forbid-foreign-prop-types
  );
  return prop => !propsToRemove.includes(prop);
}

export function removeNames(...names) {
  return prop => !names.includes(prop);
}
export function forwardNames(...names) {
  return !removeNames(...names);
}
