import styled from "styled-components/macro";
import isPropValid from "@emotion/is-prop-valid";
import {
  alignSelf,
  background,
  border,
  boxShadow,
  color,
  display,
  fontSize,
  fontStyle,
  fontWeight,
  height,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  position,
  space,
  textAlign,
  width
} from "styled-system";
import forwardProps, {
  removePropTypes,
  removeNames
} from "../utils/forwardProps";

const Div = forwardProps(
  removePropTypes(
    alignSelf,
    background,
    border,
    boxShadow,
    color,
    display,
    fontSize,
    fontStyle,
    fontWeight,
    height,
    lineHeight,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    position,
    space,
    textAlign,
    width
  ),
  removeNames("_css"),
  isPropValid
)("div");
const Box = styled(Div)`
  ${alignSelf} 
  ${background} 
  ${border}
  ${boxShadow}
  ${color} 
  ${display} 
  ${fontSize} 
  ${fontStyle} 
  ${fontWeight} 
  ${height} 
  ${lineHeight} 
  ${maxHeight}
  ${maxWidth} 
  ${minHeight} 
  ${minWidth} 
  ${position} 
  ${space}
  ${textAlign}
  ${width}
`;

Box.propTypes = {
  ...alignSelf.propTypes,
  ...background.propTypes,
  ...border.propTypes,
  ...boxShadow.propTypes,
  ...color.propTypes,
  ...display.propTypes,
  ...fontSize.propTypes,
  ...fontStyle.propTypes,
  ...fontWeight.propTypes,
  ...height.propTypes,
  ...lineHeight.propTypes,
  ...maxHeight.propTypes,
  ...maxWidth.propTypes,
  ...minHeight.propTypes,
  ...minWidth.propTypes,
  ...space.propTypes,
  ...textAlign.propTypes,
  ...width.propTypes
};

export default Box;
