import { gql } from "apollo-boost";
import React, { Suspense } from "react";
import { useQuery } from "react-apollo-hooks";
import { ThemeProvider } from "styled-components/macro";
import FavIcon from "../../components/FavIcon";
import { fragment as ImageFragment } from "../../components/Image";
import Loading from "../../components/Loading";
import useScrollRestoration from "../../components/useScrollRestoration";

export default function List(props) {
  const { lang, projectId, children, location } = props;
  const { data: { project: { theme, logo } = {} } = {} } = useQuery(query, {
    variables: { projectId, lang },
    suspend: false
  });
  useScrollRestoration(location);
  return (
    <ThemeProvider theme={mergeTheme(theme)}>
      <Suspense fallback={<Loading />}>
        <FavIcon {...logo} />
        {children}
      </Suspense>
    </ThemeProvider>
  );
}

const mergeTheme = (theme = {}) => parentTheme => ({
  ...parentTheme,
  ...theme,
  colors: { ...parentTheme.colors, ...theme.colors }
});

export const fragment = gql`
  fragment List on Project {
    name
    logo {
      ...Image
    }
    theme {
      colors {
        primary
        secondary
      }
    }
  }
  ${ImageFragment}
`;

export const query = gql`
  query ListThemeAndLayout($projectId: ID!, $lang: String) {
    project(id: $projectId, lang: $lang) {
      ...List
    }
  }
  ${fragment}
`;
