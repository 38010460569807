import React, { Suspense } from "react";
import Helmet from "react-helmet-async";
import { Col, Container, Row } from "../../components/Grid";
import Image, { AspectRatio } from "../../components/Image";
import Link from "../../components/Link";
import { Nav, NavBar, NavBarSpacer, NavLink } from "../../components/NavBar";
import Loading from "../../components/Loading";
import FavIcon from "../../components/FavIcon";

export default function EventLayout({
  children,
  logo,
  name = "Event",
  navItems,
  uri,
  theme,
  errors = []
}) {
  const { colors: { primary } = {} } = theme || {};
  return (
    <>
      <Helmet titleTemplate={`%s | ${name} | unserwein Bottlebooks`}>
        {primary && <meta name="msapplication-TileColor" content={primary} />}
        {primary && <meta name="theme-color" content={primary} />}
      </Helmet>
      <FavIcon {...logo} />

      <NavBarSpacer>
        <NavBar>
          <Container>
            <Row alignItems="center">
              <Col width={32 * 2 + 2 * 8} py={2}>
                <Link to={uri} variant="basic">
                  <AspectRatio aspectRatio={2 / 1}>
                    <Image {...logo} variant="landscapeLogo" />
                  </AspectRatio>
                </Link>
              </Col>
              <Nav>
                {navItems &&
                  navItems.map(({ id, url, title }) => (
                    <NavLink key={id} to={url}>
                      {title}
                    </NavLink>
                  ))}
              </Nav>
            </Row>
          </Container>
        </NavBar>
      </NavBarSpacer>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </>
  );
}
