import PropTypes from "prop-types";
import React from "react";
import Box from "./Box";

const variantsBase = { mt: 0, mb: 0, maxWidth: "50em" };
const variants = {
  default: { mb: "1.5em" },
  large: { fontSize: [4, 5], lineHeight: [4, 5], fontWeight: "thin" },
  prefix: {
    fontSize: 1,
    lineHeight: 1,
    fontWeight: "medium",
    color: "muted",
    mt: 0,
    mb: 0,
    pt: 1,
    pb: 1
  },
  single: { fontSize: 1, lineHeight: 1, fontWeight: "medium", pt: 0, pb: 0 },
  stand: { fontSize: 1, lineHeight: 1 },
  subline: { fontSize: 0, lineHeight: 1, fontWeight: "medium" },
  xs: { fontSize: 0, lineHeight: 1, color: "muted" }
};

export default function Text({ variant, ...rest }) {
  return (
    <Box
      {...variantsBase}
      {...variants[variant] || variants.default}
      {...rest}
    />
  );
}
Text.propTypes = {
  ...Box.propTypes,
  variant: PropTypes.oneOf(Object.keys(variants))
};
