import React from "react";
import { globalHistory } from "@reach/router";

export default function useScrollRestoration(
  { key } = {},
  history = globalHistory
) {
  const [cache, setCache] = React.useState({});
  React.useEffect(() => {
    return history.listen(
      ({ action, location: { key: nextKey, hash } = {} }) => {
        // 1) Store the current position in the cache.
        const top = window.scrollY;
        setCache(prev => {
          // console.log("storing", top, key);
          return { ...prev, [key]: top };
        });

        // 2) Schedule a scroll.
        // Scrolling only works with a timeout. An effect is to early.
        // TODO investigate further into that.
        // console.log("action", action, key, hash);
        if (action === "PUSH" && !hash) {
          // When data is already loaded, requestAnimationFrame is too early for PUSH.
          // So we use a timeout of 0. TODO Investigate why this is the case.
          const timer = setTimeout(() => {
            window.scrollTo(0, 0);
          }, 0);
          return () => clearTimeout(timer);
        } else {
          const top = cache[nextKey];
          if (top !== undefined) {
            // console.log("scheduling scroll to", top, nextKey);
            // On POP, requestAnimationFrame is ok.
            const timer = requestAnimationFrame(() => {
              window.scrollTo(0, top);
            });
            return () => cancelAnimationFrame(timer);
          }
        }
      }
    );
  }, [key]);
}
