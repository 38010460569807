import { Link as RouterLink } from "@reach/router";
import styled from "styled-components/macro";
import { color, display, space } from "styled-system";
import forwardProps, {
  removePropTypes,
  removeNames
} from "../utils/forwardProps";

const baseVariant = {
  transition: "all 0.3s ease-out",
  textDecoration: "none"
};

const variants = {
  basic: {},
  hover: props => ({
    ":hover": {
      color: props.theme.colors.primary,
      textDecoration: "underline"
    }
  }),
  default: props => ({
    color: props.theme.colors.primary,
    ":hover": {
      textDecoration: "underline"
    }
  }),
  more: props => ({
    fontSize: props.theme.fontSizes[0],
    lineHeight: props.theme.lineHeights[0],
    color: props.theme.colors.primary,
    textTransform: "uppercase",
    "::after": {
      content: '"⟶"',
      display: "inline-block",
      fontSize: props.theme.fontSizes[3],
      lineHeight: props.theme.lineHeights[0],
      paddingLeft: "0.5em"
    },
    ":hover": {
      textDecoration: "underline"
    }
  }),
  back: props => ({
    ...variants.more(props),
    "::after": undefined,
    "::before": {
      ...variants.more(props)["::after"],
      content: '"⟵"',
      paddingLeft: 0,
      paddingRight: "0.5em"
    }
  })
};

const Link = styled(
  forwardProps(removePropTypes(color, display, space), removeNames("variant"))(
    RouterLink
  )
)(
  color,
  display,
  space,
  baseVariant,
  props => props.css,
  props => variants[props.variant] || variants.default
);
Link.defaultProps = { color: "inherit" };

export default Link;
