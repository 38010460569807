import styled from "styled-components/macro";
import { Col } from "./Grid";
import Link from "./Link";
import Box from "./Box";

export const NavBarSpacer = styled(Box)`
  height: 16px;
`;
NavBarSpacer.defaultProps = { py: 3, fontSize: 1, lineHeight: 1 };

export const NavBar = styled(Box)`
  position: fixed;
  z-index: 1;
  width: 100%;
`;
NavBar.defaultProps = { bg: "toolbarBackground", mt: -3, boxShadow: "md" };

export const Nav = styled(Col.withComponent("nav"))`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const NavLink = styled(Link)`
  font-weight: 500;
  transition: color 0.3s ease-out, background 0.3s ease-out;
  ${({ theme }) => ({
    "&[data-current]": { color: theme.colors.text },
    ":hover": {
      color: theme.colors.text,
      backgroundColor: theme.colors.soft[1]
    }
  })}
`;
NavLink.defaultProps = {
  color: "muted",
  display: "block",
  px: 2,
  py: 3,
  variant: "basic",
  getProps: ({ isPartiallyCurrent, isCurrent }) => ({
    "data-current": isPartiallyCurrent
      ? isCurrent
        ? "page"
        : "location"
      : undefined
  })
};
