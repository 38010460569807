import { gql } from "apollo-boost";

export const defaults = {
  // producersFilter: { query: "test", __typename: "ProducersFilter" }
  // event: {
  //   producersConnection: { query: "" }
  // }
  producersFilter: { id: "producers", text: "", __typename: "Filter" },
  productsFilter: { id: "products", text: "", __typename: "Filter" }
};

export const resolvers = {
  Mutation: {
    updateProducersFilterText: (parent, { text }, { cache, getCacheKey }) => {
      const query = gql`
        query ProducersFilter {
          producersFilter @client {
            id
            text
          }
        }
      `;
      const { producersFilter } = cache.readQuery({ query });
      const data = { ...producersFilter, text };
      const id = getCacheKey({ __typename: "Filter", id: "producers" });
      cache.writeData({ id, data });
      return text;
    },

    updateProductsFilterText: (parent, { text }, { cache, getCacheKey }) => {
      const query = gql`
        query ProductsFilter {
          productsFilter @client {
            id
            text
          }
        }
      `;
      const { productsFilter } = cache.readQuery({ query });
      const data = { ...productsFilter, text };
      const id = getCacheKey({ __typename: "Filter", id: "products" });
      cache.writeData({ id, data });
      return text;
    }
  },

  Query: {
    producersFilter: parent => parent.producersFilter,
    productsFilter: parent => parent.productsFilter
  }
};
