// import React from "react";
import styled from "styled-components/macro";
import Box from "./Box";
import Flex from "./Flex";

export const Container = styled(Box)``;
Container.defaultProps = {
  mx: "auto",
  maxWidth: [960, null, null, null, 1184],
  pl: 2,
  pr: 2
};
Container.propTypes = {
  ...Box.propTypes
};

export const Row = styled(Flex)``;
Row.defaultProps = { ...Flex.defaultProps, ml: -2, mr: -2, flexWrap: "wrap" };
Row.propTypes = {
  ...Flex.propTypes
};

export const Col = styled(Flex)`
  box-sizing: border-box;
`;
Col.defaultProps = { pl: 2, pr: 2 }; // No flex defaults!
Col.propTypes = {
  ...Flex.propTypes
};
