import { Redirect, Router } from "@reach/router";
import ApolloClient from "apollo-boost";
import { CloudinaryContext } from "cloudinary-react";
import React from "react";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components/macro";
import Event from "./features/event/Event";
import List from "./features/list/List";
import Site from "./features/site/Site";
import theme from "./theme";
import { defaults, resolvers } from "./resolvers";
import "iframe-resizer/js/iframeResizer.contentWindow";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  clientState: {
    defaults,
    resolvers
  }
});
// const Event = React.lazy(() =>
//   import(/* webpackChunkName: "Event" */ "./features/event/Event")
// );
const ProducersPage = React.lazy(() =>
  import(
    /* webpackChunkName: "ProducersPage" */ "./features/producer/ProducersPage"
  )
);
const ProducerPage = React.lazy(() =>
  import(
    /* webpackChunkName: "ProducerPage" */ "./features/producer/ProducerPage"
  )
);
const ProductsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "ProductsPage" */ "./features/product/ProductsPage"
  )
);
const ProductPage = React.lazy(() =>
  import(
    /* webpackChunkName: "ProductPage" */ "./features/product/ProductPage/ProductPage"
  )
);
const ListProductsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "ProductsPage" */ "./features/product/ListProductsPage"
  )
);
const ListProductPage = React.lazy(() =>
  import(
    /* webpackChunkName: "ProductPage" */ "./features/product/ProductPage/ListProductPage"
  )
);
const IntegrationPage = React.lazy(() =>
  import(
    /* webpackChunkName: "IntegrationPage" */ "./features/integration/IntegrationPage"
  )
);

const SiteMainPage = React.lazy(() =>
  import(/* webpackChunkName: "Site" */ "./features/site/SiteMainPage")
);

const Group = ({ children }) => children;

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <ApolloHooksProvider client={client}>
          <CloudinaryContext cloudName="unserwein">
            <Router>
              {/* ## Events */}

              {/* Redirect to producers by default (for now). */}
              <Redirect
                from=":lang/events/:projectId"
                to=":lang/events/:projectId/producers"
                noThrow
              />
              <Event path=":lang/events/:projectId">
                <IntegrationPage path="integration" />
                <Group path="producers">
                  <ProducersPage path="/" />
                  <ProducerPage path=":producerId" />
                  <Group path=":producerId/products">
                    <ProductPage path=":productId" basePath="../../" />
                  </Group>
                </Group>
                <ProducersPage path="exhibitors" />
                <ProducerPage path="exhibitors/:brandId" />
                <Group path="products">
                  <ProductsPage path="/" />
                  <ProductPage path=":productId" withAllProducts />
                </Group>
              </Event>

              {/* ## Lists */}
              <Redirect
                from=":lang/lists/:projectId"
                to=":lang/lists/:projectId/products"
                noThrow
              />
              <List path=":lang/lists/:projectId">
                <Group path="products">
                  <ListProductsPage path="/" />
                  <ListProductPage path=":productId" />
                </Group>
              </List>

              {/* ## Sites */}
              <Redirect
                from=":lang/sites/:siteId/lists/:projectId"
                to=":lang/sites/:siteId/lists/:projectId/products"
                noThrow
              />
              <Site path=":lang/sites/:siteId">
                <SiteMainPage path="/" />
                <SiteMainPage path="producers/:producerId" basePath="../../" />
                <List path="lists/:projectId">
                  <Group path="products">
                    <ListProductsPage path="/" />
                    <ProductPage path=":productId" basePath="../../" />
                  </Group>
                </List>
              </Site>
            </Router>
          </CloudinaryContext>
        </ApolloHooksProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
