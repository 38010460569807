import styled from "styled-components/macro";
import {
  alignItems,
  flex,
  flexDirection,
  flexWrap,
  justifyContent
} from "styled-system";
import forwardProps, { removePropTypes } from "../utils/forwardProps";
import Box from "./Box";

const Flex = styled(
  forwardProps(
    removePropTypes(alignItems, flex, flexDirection, flexWrap, justifyContent)
  )(Box)
)`
  ${alignItems}
  ${flex}
  ${flexDirection}
  ${flexWrap}
  ${justifyContent}
`;
Flex.defaultProps = { display: "flex" };
Flex.propTypes = {
  ...Box.propTypes,
  ...alignItems.propTypes,
  ...flex.propTypes,
  ...flexDirection.propTypes,
  ...flexWrap.propTypes,
  ...justifyContent.propTypes
};
export default Flex;
