import React from "react";
import Helmet from "react-helmet-async";
import useCloudinary from "./useCloudinary";

export default function FavIcon({ publicId }) {
  const getImage = useCloudinary(publicId, { crop: "pad", format: "png" });
  if (!publicId) return null;
  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={getImage({ width: 57, height: 57 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={getImage({ width: 60, height: 60 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={getImage({ width: 72, height: 72 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={getImage({ width: 76, height: 76 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={getImage({ width: 114, height: 114 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={getImage({ width: 120, height: 120 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={getImage({ width: 144, height: 144 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={getImage({ width: 152, height: 152 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={getImage({ width: 180, height: 180 })}
      />
      <link
        rel="shortcut icon"
        type="image/png"
        sizes="32x32"
        href={getImage({ width: 32, height: 32 })}
      />
      <link
        rel="shortcut icon"
        type="image/png"
        sizes="16x16"
        href={getImage({ width: 16, height: 16 })}
      />
    </Helmet>
  );
}
