import { gql } from "apollo-boost";
import { Image as CloudinaryImage } from "cloudinary-react";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

export const AspectRatio = styled.div`
  position: relative;
  ::before {
    content: "";
    display: block;
    float: left;
    width: 0;
    padding-bottom: ${props => 100 / props.aspectRatio}%;
  }
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;
AspectRatio.propTypes = {
  aspectRatio: PropTypes.number.isRequired
};

export default function Image(props) {
  const { publicId, url, aspectX, aspectY, variant, ...imgProps } = props;
  if (!publicId && !url) return null;
  if (!publicId) return <StyledRegularImage src={url} {...imgProps} />;

  return (
    <StyledCloudinaryImage
      publicId={publicId}
      {...getVariant(props)}
      {...imgProps}
    />
  );
}

export const fragment = gql`
  fragment Image on Image {
    url
    publicId
    format
  }
`;

export function getVariant(props) {
  function getDefaults(p) {
    return {
      format: p.format,
      fetchFormat: "auto",
      aspectRatio:
        p.aspectX && p.aspectY ? `${p.aspectX}:${p.aspectY}` : undefined
    };
  }

  const variants = {
    default: props => getDefaults(props),
    bottle: props => ({
      ...getDefaults({ aspectX: 1, aspectY: 2, ...props }),
      crop: "pad",
      dpr: "auto",
      gravity: "center",
      responsive_use_breakpoints: true,
      responsive: true,
      transformation: [
        { effect: "trim:20", height: 4096, width: 4096, crop: "limit" },
        { effect: "make_transparent:5" },
        { effect: "trim:0" }
      ],
      width: "auto:50"
    }),
    header: props => ({
      ...getDefaults({ aspectX: 4, aspectY: 1, ...props }),
      crop: "fill",
      dpr: "auto",
      fetchFormat: "auto",
      gravity: "faces:center",
      responsive_use_breakpoints: true,
      responsive: true,
      width: "auto:160"
    }),
    logo: props => ({
      ...getDefaults({ aspectX: 1, aspectY: 1, ...props }),
      crop: "pad",
      dpr: "auto",
      fetchFormat: "auto",
      gravity: "center",
      responsive_use_breakpoints: true,
      responsive: true,
      width: "auto:50"
    }),
    landscapeLogo: props => ({
      ...variants.logo({ aspectX: 2, aspectY: 1, ...props })
    })
  };
  return (variants[props.variant] || variants.default)(props);
}

const StyledRegularImage = styled.img`
  display: block;
  max-width: 100%;
  ${props => props.css}
`;

const StyledCloudinaryImage = StyledRegularImage.withComponent(CloudinaryImage);
